import React from "react";

const ErrorLayout = () => {
  return (
    <React.Fragment>
      <div>
        <h1>ErrorLayout</h1>
      </div>
    </React.Fragment>
  );
};

export default ErrorLayout;
