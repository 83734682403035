import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Login from "./components/Login";
import Register from "./components/Register";
import AuthLayout from "./layout/AuthLayout";
import ErrorLayout from "./layout/ErrorLayout";

function App() {
  const isLoggedIn = false;
  return (
    <div className="App">
      {isLoggedIn ? <AuthLayout /> : <ErrorLayout />}
      <Switch>
        <Route component={Login} exact path="/login" />
        <Route component={Register} exact path="/register" />
        <Route component={Login}  path="/" />
      </Switch>
    </div>
  );
}

export default App;
