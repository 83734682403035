import React from "react";
import { useState } from "react";
import { FC } from "react";
import "./Register.css";

type LoginProps = {
  tittle: string;
};

const Register: FC<LoginProps> = (props) => {
  console.log("rendes");

  const [val, setVal] = useState<string>('ash');
  return (
    <div className="login">
      {props.tittle}
      <div className="login__form">
        Username <input type="text" />
        <div>
          Password <input type="text" value={val} onChange={(e) => {setVal(e.target.value)}} />
        </div>
        <button>Register</button>
      </div>
    </div>
  );
};

export default Register;
